<template>
    <div>
        <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refVendorTable'].refresh()"
        >
                <b-table
                slot="table"
                ref="refVendorTable"
                class="position-relative"
                :items="myProvider"
                responsive
                :fields="VendorFields"
                small
                primary-key="id"
                sticky-header="50vh"
                show-empty
                empty-text="No matching records found"
                :per-page="paginate.perPage"
                :current-page="paginate.currentPage"
                :busy="isBusy"
                no-provider-filtering
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle mr-1" />
                            <strong>Loading ...</strong>
                        </div>
                    </template>
                    <template #cell(company)="data">
                        <div>
                            <feather-icon
                                size="15"
                                icon="HomeIcon"
                                class="text-warning"
                                v-b-tooltip.hover.top="'Name'"
                            />
                            {{data.item.name}}
                        </div>
                        <div>
                            <feather-icon
                                size="15"
                                icon="HashIcon"
                                class="text-warning"
                                 v-b-tooltip.hover.top="'RUC / EIN'"
                            />
                            {{data.item.ruc}}
                        </div>
                    </template>
                    <template #cell(website)="data">
                        <div>
                            <b-link v-if="data.item.website != null" :href="website(data.item)" target="_blank">
                                <tabler-icon
                                    icon="WorldIcon"
                                    class="text-primary"
                                    style="margin-right: 0.5rem"
                                />{{data.item.website}}</b-link>    
                            <div v-else :class="data.item.website == null ? 'text-danger': ''">No website</div>                    
                        </div>
                    </template>
                    <template #cell(contact)="data">
                        <div :class="data.item.email == null ? 'text-danger': ''">
                            <feather-icon
                            icon="MailIcon"
                            size="15"
                            style="color: #5FB404"
                            />
                            {{data.item.email || "No email"}}
                        </div>
                        <div :class="data.item.phone == null ? 'text-danger': ''">
                            <feather-icon
                            icon="PhoneIcon"
                            size="15"
                            style="color: #5FB404"
                            />
                            {{phone(data.item)}}
                        </div>
                    </template>
                    <template #cell(address)="data">
                        <div :class="data.item.address == null ? 'text-danger': ''">
                            <feather-icon
                            icon="MapPinIcon"
                            size="15"
                            class="text-danger"
                            />
                            {{data.item.address || "No address"}}
                        </div>
                    </template>
                    <template #cell(rating)="data">
                        <template v-if="ratingData(data.item).length != 0" >
                            <div v-for="(rate, index) in ratingData(data.item)" :key="index" class="d-flex">
                                <template>
                                    <div class="d-flex align-items-center">
                                        <p class="p-0 m-0">{{rate.name}}: </p>
                                    </div>
                                    <div>
                                        <b-form-rating 
                                        v-model="rate.score"
                                        variant="warning"
                                        size="sm" 
                                        readonly
                                        no-border
                                        class="p-0 m-0">
                                        </b-form-rating>
                                    </div>
                                </template>                                                  
                            </div>
                        </template>
                        <div v-else class="text-danger">
                            No rating yet
                        </div>      
                    </template>
                    <template #cell(purchases)="data">
                        <p>Purchase orders: {{data.item.qty_purchases}}</p>
                        <p><b-badge pill variant="warning" v-b-tooltip.hover.top="'Total amount calculated in dollars'"><strong>$ </strong></b-badge> {{twoDecimals(data.item.total_dollars)}}</p>
                        <p><b-badge pill variant="warning" v-b-tooltip.hover.top="'Total amount calculated in soles'"><strong>S </strong></b-badge> {{twoDecimals(data.item.total_soles)}}</p>
                    </template>
                    <template #cell(actions)="data">
                        <feather-icon
                        icon="Edit2Icon"
                        size="15"
                        class="text-warning cursor-pointer mr-1"
                        @click="editVendor(data.item)"
                        />
                        <feather-icon
                        icon="TrashIcon"
                        size="15"
                        class="text-danger cursor-pointer"
                        @click="deleteVendor(data.item.id)"
                        />
                    </template>
                </b-table>
        </filter-slot>
        <VendorModal 
        v-if="showVendorModal" 
        :vendorData="vendorSelected" 
        :typeModal="'EDIT'" 
        @refreshTable="refreshTable"
        @closeModal="closeModal">
        </VendorModal>
    </div>
</template>
<script>
import VendorFields from "../data/flieds.vendors"
import VendorService from "./../service/vendors.service"
import VendorModal from "./modals/VendorModal.vue"
import {mapGetters, mapActions} from "vuex"
export default {
    data(){
        return {
            VendorFields,
            showVendorModal: false,
            vendorSelected: [],
            filterPrincipal: {
                type: "input",
                inputType: "text",
                placeholder: "Search...",
                model: "",
            },

            filters: [],
            paginate: {
                currentPage: 1,
                perPage: 10,
            },
            startPage: 0,
            toPage: 0,
            totalRows: 0,
            isBusy: false,
        }
    },
    props: {
        location: Number,
    },
    components:{
        VendorModal,
    },
    watch:{
        getRefreshTable(value){
            if(value){
                this.refreshTable();
                this.CHANGE_REFRESH_TABLE(false);
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            getRefreshTable: "VendorStore/getRefreshTable",
        }),  
    },
    methods: {
        ...mapActions({
            CHANGE_REFRESH_TABLE: "VendorStore/CHANGE_REFRESH_TABLE",
        }),
        async myProvider(){
            this.isBusy = true;
            const params = {
                location: this.location,
                name_text: this.filterPrincipal.model,
                per_page: this.paginate.perPage,
                page: this.paginate.currentPage,
            }
            const {data} = await VendorService.getData(params);
            this.startPage = data.from;
            this.toPage = data.to;
            this.totalRows = data.total;
            this.paginate.currentPage = data.current_page;
            this.paginate.perPage = data.per_page;
            this.isBusy = false;
            return data.data;
        },
        editVendor(vendor){
            this.showVendorModal = true;
            this.vendorSelected = vendor;
        }, 
        async deleteVendor(id){
            const confirm = await this.showConfirmSwal();
            if(confirm.value){
                const params = {
                    user_id: this.currentUser.user_id,
                    id: id
                }
                try {
                    const data = await VendorService.deleteData(params);
                    if(data.success){
                        this.showToast(
                            "success",
                            "top-right",
                            "Success!",
                            "CheckIcon",
                            "Successful operation"
                        );
                        this.refreshTable();
                    }else{
                        this.showToast(
                            "danger",
                            "top-right",
                            "Failed!",
                            "XIcon",
                            "Failed operation"
                        );
                    }
                } catch (error) {
                    this.showErrorSwal(error);
                }
            }
        },
        phone(data) {
            let aux;
            if(data.phone == null){
                aux = "No phone"
            }else{
                if (data.extension != null) {
                    aux = data.phone + " (" + data.extension + ")";
                } else {
                    aux = data.phone;
                }
            }
            return aux;
        },   
        website(data){
            let aux = null;
            if(data.website != null) {
                aux = "https://" + data.website;
            }
            return aux;
        },  
        ratingData(data){
            let aux = JSON.parse(data.rating)
            aux = aux.filter(item => item.deleted_at == null);
            aux.sort((a, b) => a.id - b.id);
            return aux.filter(rate => rate.country_rate_id == data.country_id);
        },
        twoDecimals(amount){
            return amount == null ? '0.00' : parseFloat(amount).toFixed(2)
        },
        closeModal(){
            this.showVendorModal = false;
        },
        refreshTable(){
            this.$refs['refVendorTable'].refresh()
        }
    },

}
</script>