<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      :title="titleModal"
      @hidden="closeModal"
    >
      <ValidationObserver ref="form">
        <div>
          <b-row>
            <!-- COUNTRIES -->
            <b-col sm="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="Country:">
                  <b-form-select
                    :options="countries"
                    placeholder="Select a country"
                    v-model="form.country_id"
                    value-field="id"
                    text-field="name"
                    class="select-icon-none"
                    :state="errors[0] ? false : null"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Select an option</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- VENDOR -->
            <b-col sm="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="Vendor:">
                  <b-form-input
                    v-model="form.name"
                    class="input-background-white"
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- RUC / EIN -->
            <b-col sm="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="RUC / EIN">
                  <b-form-input
                    v-model="form.ruc"
                    class="input-background-white no-spinners"
                    :state="errors[0] ? false : null"
                    no-wheel
                    v-mask="'##############'"
                  ></b-form-input>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- ADDRESS -->
            <b-col sm="6">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-group label="Address">
                  <div class="d-flex align-items-center">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      placeholder=""
                      v-model="form.address"
                      class="form-control input-form fond-white"
                      :country="countrySelected.iso"
                      :class="{ 'border border-danger': errors[0] }"
                      :disabled="disabledAddress"
                      v-on:placechanged="getAddressData"
                    />
                    <b-button variant="primary" :disabled="disabledEdit" @click="unableAddress()">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </div>
                  <span v-if="errors[0]" class="text-danger">
                    Address {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- EMAIL -->
            <b-col sm="6">
              <ValidationProvider rules="email" v-slot="{ errors }">
                <b-form-group label="Email:">
                  <b-form-input
                    v-model="form.email"
                    class="input-background-white"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- WEBSITE -->
            <b-col sm="6">
              <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
              <b-form-group label="Website:">
                <b-input-group prepend="https://">
                <b-form-input
                  v-model="form.website"
                  class="input-background-white"
                ></b-form-input>
              </b-input-group>
              </b-form-group>
              <!-- </ValidationProvider> -->
            </b-col>

            <!-- PHONE -->
            <b-col sm="6">
              <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
              <b-form-group label="Phone:">
                <b-form-input
                  v-model="form.phone"
                  class="input-background-white"
                  v-mask="maskPhone"
                  :disabled="disabledPhone"
                ></b-form-input>
              </b-form-group>
              <!-- </ValidationProvider> -->
            </b-col>

            <!-- EXTENSION -->
            <b-col sm="6">
              <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
              <b-form-group label="Extension:">
                <b-form-input
                  v-model="form.extension"
                  class="input-background-white no-spinners"
                  :disabled="isDisabled"
                  no-wheel
                  v-mask="'####'"
                ></b-form-input>
              </b-form-group>
              <!-- </ValidationProvider> -->
            </b-col>
          </b-row>
        </div>
      </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <!-- Footer: Left Content -->
        <b-button variant="danger" class="px-1" @click="cancel()"
          >CANCEL</b-button
        >
        <b-button variant="primary" class="px-1" @click="saveData()">{{
          titleButton
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VendorService from "../../service/vendors.service";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    typeModal: String,
    vendorData: Object,
    openedFrom: {
      type: String,
      default: null,
    },
  },
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      showModal: false,
      form: {
        type: null,
        country_id: null,
      },
      countries: [],
      isDisabled: true,
      disabledPhone: true,
      address: "",
      disabledAddress: true,
      countrySelected: {},
      disabledEdit: true,
    };
  },
  async created() {
    await this.getCountries();
    await this.valuateData();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    titleModal() {
      let aux = this.typeModal == "ADD" ? "CREATE" : "EDIT";
      return aux + " VENDOR";
    },
    titleButton() {
      return this.typeModal == "ADD" ? "SAVE" : "UPDATE";
    },
    maskPhone() {
      if (this.form.country_id == 75) {
        return "(###)-###-####";
      } else if (this.form.country_id == 173) {
        return "###-###-###";
      }
      return "";
    },
  },
  watch: {
    "form.phone": function (value) {
      if (value == null || value == "" || value == undefined) {
        this.isDisabled = true;
        this.form.extension = null;
      } else {
        this.isDisabled = false;
      }
    },
    "form.country_id": function (value) {
      this.countrySelected = this.countries.find(
        (country) => country.id == value
      );
      if (value == null || value == "" || value == undefined) {
        this.disabledPhone = true;
        this.disabledAddress = true;
      } else {
        this.disabledPhone = false;
        this.disabledAddress = false;
      }
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "VendorStore/CHANGE_REFRESH_TABLE",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async saveData() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {         
          const param = {
            id: this.typeModal == "EDIT" ? this.vendorData.id : null,
            country: this.form.country_id,
            ruc: this.form.ruc
          }; 
          let isDuplicate = await VendorService.duplicateValidator(param);
          if (isDuplicate) {              
            this.showWarningSwal("Warning", "Duplicated RUC/EIN");          
          }else{
            this.addPreloader();
            let typeService = this.typeModal == "ADD" ? "saveData" : "updateData";
            const params = {
              id: this.form.id,
              name: this.form.name,
              ruc: this.form.ruc,
              website: this.form.website,
              address: this.form.address,
              email: this.form.email,
              phone: this.form.phone,
              extension: this.form.extension,
              location: this.form.location,
              createdBy: this.currentUser.user_id,
              bussiness: this.form.bussiness,
              country: this.form.country_id,
              updatedBy: this.currentUser.user_id,
            };
            try {
              let { data } = await VendorService[typeService](params);
              if (data.success) {              
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful operation"
                );
                if (this.typeModal == "ADD" && this.openedFrom == null) {
                  let routeName = "vendors-peru";
                  if (this.form.country_id == 75) {
                    routeName = "vendors-usa";
                  } else if (this.form.country_id == 173) {
                    routeName = "vendors-peru";
                  }
                  this.$router.push({ name: routeName }).catch((err) => {
                    if (
                      err.name !== "NavigationDuplicated" &&
                      !err.message.includes(
                        "Avoided redundant navigation to current location"
                      )
                    ) {
                      throw err;
                    }
                  });
                  this.CHANGE_REFRESH_TABLE(true);
                } else {
                  this.refreshTable();
                }
                this.closeModal();
              } else {
                this.showToast(
                  "danger",
                  "top-right",
                  "Failed!",
                  "XIcon",
                  "Failed operation"
                );
              }
            } catch (error) {
              this.showErrorSwal(error);
            }
            this.removePreloader();
          }
          
        }
      }
    },
    valuateData() {
      if (this.typeModal == "EDIT") {
        this.form = { ...this.vendorData };
        this.disabledEdit = false;
      }
      this.showModal = true;
    },    
    async getCountries() {
      const { data } = await VendorService.getCountries();
      let itemsFiltered = data.filter(
        (country) => country.id == 75 || country.id == 173
      );
      let object = itemsFiltered.find(
        (country) => country.name == "Estados Unidos"
      );
      if (object) {
        object.name = "USA";
      }
      let itemsUppercase = itemsFiltered.map((item) => {
        return { ...item, name: item.name.toUpperCase() };
      });

      this.countries = itemsUppercase;
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.disabledEdit = false;
      this.disabledAddress = true;
      this.form.address = addressData.route + ", " + addressData.locality;
    },
    unableAddress(){
      this.disabledAddress = false
      this.disabledEdit = true
      this.form.address = null
      this.$refs.address.clear()
    },
    closeModal() {
      this.$emit("closeModal");
    },
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
<style>
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>


