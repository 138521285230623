import { amgApi } from '@/service/axios'

class VendorService {
    
    async getData(params){
        const data = await amgApi.post("/logistics/vendors/get", params)
        return data
    }

    async saveData(params) {
        const data = await amgApi.post("/logistics/vendors/add", params)
        return data
    }

    async updateData(params) {
        const data = await amgApi.post("/logistics/vendors/update", params)
        return data
    }

    async getCountries(){
        const data = await amgApi.post("/commons/get-world-countrys")
        return data
    }

    async deleteData(params){
        const {data} = await amgApi.post("/logistics/vendors/delete", params)
        return data
    }

    async duplicateValidator(params){
        const {data} = await amgApi.post("/logistics/vendors/duplicate-validator", params)
        return data
    }
}

export default new VendorService()