export default [
    {
        key: "company",
        label: "Vendor",
        class: "text-left",
        sortable: false,
    },
    {
        key: "website",
        label: "Website",
        class: "text-left",
        sortable: false,
    },
    {
        key: "contact",
        label: "Contact",
        class: "text-left ",
        sortable: false,
    },
    {
        key: "address",
        label: "Address",
        class: "text-left",
        sortable: false,
    },
    {
        key: "rating",
        label: "General rating",
        class: "text-left",
        sortable: false,
    },
    {
        key: "purchases",
        label: "Purchases info",
        class: "text-left",
        sortable: false,
    },
    {
        key: "actions",
        label: "Actions",
        class: "text-left",
        sortable: false,
    }
]